<!-- 
    页面 登陆
-->

<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-6">
                  <h4 class="mb-4">后台服务登入</h4>
                  <p>欢迎管理员回来，请登入你的账号</p>
                </div>

                <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
                  <!-- 登陆表单认证(账号) -->
                  <el-form-item label="用户账号" prop="account">
                    <el-input v-model="form.account" prefix-icon="el-icon-user" placeholder="请输入用户账号"></el-input>
                  </el-form-item>

                  <!-- 登陆表单认证(密码) -->
                  <el-form-item label="用户密码" prop="password">
                    <el-input type="password" v-model="form.password" prefix-icon="el-icon-lock" placeholder="请输入用户密码"></el-input>
                  </el-form-item>
                </el-form>

                <div class="flex flex-wrap justify-between"></div>

                <!-- 登陆表单认证(提交) -->
                <vs-button id="loginBtn" class="w-full vs-con-loading__container" :disabled="!validateForm" @click.prevent="submitForm('ruleForm')">登入</vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { Base64 } from 'js-base64'
import router from '@/router'
export default {
  data() {
    return {
      form: {
        account: 'admin',
        password: ''
      },
      rules: {
        account: [{ required: true, message: '请输入用户账号', trigger: 'blur' }],
        password: [{ required: true, message: '请输入用户密码', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    let t = this.$route.query.token
    // if (this.$store.state.auth.isUserLoggedIn()) {
    //   router.push(router.currentRoute.query.to || '/')
    // } else {
    if (t) {
      const payload = {
        token: t,
        notify: this.$vs.notify,
        isAuthLoading: true
      }
      this.$store.dispatch('auth/autoLogin', payload)
    }
    // }
  },
  computed: {
    validateForm() {
      return this.form.account != '' && this.form.password != ''
    }
  },
  methods: {
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await this.login()
        } else {
          return false
        }
      })
    },
    // 登陆逻辑
    async login() {
      const payload = {
        userDetails: {
          account: this.form.account,
          password: this.form.password
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading,
        loadingDom: event.currentTarget,
        isAuthLoading: false
      }
      this.$store.dispatch('auth/login', payload)
    }
  }
}
</script>

<style lang="scss">
#page-login {
  .vs-input--placeholder {
    padding-left: 3rem;
    padding-top: 0.9rem !important;
  }
}
</style>
